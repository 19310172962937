// Navigation 1
let navButton = document.querySelector(".nav__button");
let nav = document.querySelector(".nav");
let navItems = document.querySelectorAll(".nav .nav__item a");

function closeNav() {
  navButton.classList.remove("nav__button--open");
  nav.classList.remove("nav--open");
}

navButton.addEventListener("click", (event) => {
  event.stopPropagation(); // Verhindert das Auslösen des document click-Event-Listeners
  navButton.classList.toggle("nav__button--open");
  nav.classList.toggle("nav--open");
});

navItems.forEach((item) => {
  item.addEventListener("click", function(event) {
    const targetElement = document.querySelector(event.target.getAttribute('href'));
    if (targetElement) { // Prüfen, ob das Ziel-Element existiert
      event.preventDefault();
      closeNav();
      targetElement.scrollIntoView({behavior: "smooth"});
    }
  });
});

document.addEventListener("click", (event) => {
  // Überprüft, ob weder das navButton-Element noch das nav-Element den Zielknoten des click-Events enthält
  if (!navButton.contains(event.target) && !nav.contains(event.target)) {
    closeNav();
  }
});

// Scroll NAV 
let lastScrollTop = 0;

window.addEventListener("scroll", function() {
   let scroll_nav = document.querySelector(".nav_scroll");
   let st = window.pageYOffset || document.documentElement.scrollTop; 
   if (st < lastScrollTop){
      // Wenn nach oben gescrollt wird
      if (st > 300) {
          scroll_nav.classList.add("show"); // Die Klasse hinzufügen
      } else {
          // Wenn weniger als 300px gescrollt wurden, Klasse entfernen
          scroll_nav.classList.remove("show"); // Die Klasse entfernen
      }
    } else {
      // Wenn nach unten gescrollt wird, Klasse entfernen
      scroll_nav.classList.remove("show"); 
   }

   lastScrollTop = st <= 0 ? 0 : st; // Für IE- und Chrome-Browser
}, false);

// Navigation 2
let navButton_scroll = document.querySelector(".nav__button_scroll");
let nav_scroll = document.querySelector(".nav_scroll");
let navItems_scroll = document.querySelectorAll(".nav_scroll .nav__item a");

function closeNav_scroll() {
  navButton_scroll.classList.remove("nav__button--open");
  nav_scroll.classList.remove("nav--open");
}

navButton_scroll.addEventListener("click", (event) => {
  event.stopPropagation(); // Verhindert das Auslösen des document click-Event-Listeners
  navButton_scroll.classList.toggle("nav__button--open");
  nav_scroll.classList.toggle("nav--open");
});

navItems_scroll.forEach((item) => {
  item.addEventListener("click", function(event) {
    const targetElement = document.querySelector(event.target.getAttribute('href'));
    if (targetElement) { // Überprüfen Sie, ob das Ziel-Element existiert
      event.preventDefault();
      closeNav_scroll(); // Schließen Sie das Menü sofort
      targetElement.scrollIntoView({behavior: "smooth"});
      setTimeout(function(){ // Beginnen Sie eine Timeout-Funktion
        const nav_scroll = document.querySelector(".nav_scroll"); // Wählen Sie das Element nav_scroll aus
        nav_scroll.classList.remove("show"); // Entfernen Sie die Klasse 'show' von nav_scroll
      }, 1000); // Verzögern Sie das Entfernen der Klasse um 1 Sekunde (1000 Millisekunden)
    }
  });
});


document.addEventListener("click", (event) => {
  // Überprüft, ob weder das navButton_scroll-Element noch das nav-Element den Zielknoten des click-Events enthält
  if (!navButton_scroll.contains(event.target) && !nav_scroll.contains(event.target)) {
    closeNav_scroll();
  }
});


// Slideshows Header
var swiper = new Swiper ('.header_slider', {
  loop: true,
  autoplay: {
    delay: 6000,
    disableOnInteraction: false,
  },
  speed: 1000,

  // Navigation arrows
  navigation: {
    nextEl: '.swiper-button-next',
    prevEl: '.swiper-button-prev',
  },
});

// Slideshows Referenzen
var swiper2 = new Swiper (".referenzen_swiper", {
  slidesPerView: 1,
  loop: false,
  initialSlide: 1,
  duplicateSlides: true,


  // Navigation arrows
  navigation: {
    nextEl: '.swiper-button-next',
    prevEl: '.swiper-button-prev',
  },
});


// Warte auf das DOMContentLoaded-Event, bevor der Code ausgeführt wird
document.addEventListener('DOMContentLoaded', () => {
  // Finde alle Teaser
  const teasers = document.querySelectorAll('.tease');

  // Füge jedem Teaser einen Click-Event hinzu
  teasers.forEach(teaser => {
    teaser.addEventListener('click', () => {
      // Finde den Text innerhalb des Teasers
      const text = teaser.querySelector('.text');

      // Füge oder entferne die Klasse 'open', um den Text aus- oder einzufahren
      if (teaser.classList.contains('open')) {
        teaser.classList.remove('open');
      } else {
        teaser.classList.add('open');
      }
    });

    // Füge ein Event-Listener zum Dokument hinzu
    document.addEventListener('click', event => {
      // Überprüfe, ob der Benutzer außerhalb des Teasers geklickt hat
      if (!teaser.contains(event.target)) {
        // Wenn ja, entferne die Klasse 'open', um den Text einzufahren
        teaser.classList.remove('open');
      }
    });
  });
});